import React from "react";
import styled from "styled-components";

const ErrorPage = () => {
    return (

        <Logo>                     
            <a href = "/">
                <img src="/images/mafazexportslogo.svg" alt="" />
            </a>
            <h2>It's An Error Page</h2>


        </Logo>

    );
};

const Logo = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    a {
        text-decoration: none;
        color: #131826;
    }
    h2 {
        font-size: 30px;
    }
    @media (max-width: 768px) {
        img {
            max-width: 100vw;
            max-height: 100vh;
        }
    }
`;


export default ErrorPage;