import React from "react";
import "./CardSlider2.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdChevronLeft,MdChevronRight } from "react-icons/md";


const CardSlider2 = () => {

    const slides = [
        {image:"/images/sectors/Hotels.svg", title:"Hotels", /*description:"this is a description", clickEvent:sliderClick*/},
        {image:"/images/sectors/Bakery.svg", title:"Bakery", /*description:"this is 2nd description", clickEvent:sliderClick*/},
        {image:"/images/sectors/Catering.svg", title:"Catering", /*description:"this is 3rd description", clickEvent:sliderClick*/},
        {image:"/images/sectors/Restaurants.svg", title:"Restaurants", /*description:"this is 4th description", clickEvent:sliderClick*/},
        {image:"/images/sectors/Supermarket.svg", title:"Supermarket", /*description:"this is 5th description", clickEvent:sliderClick*/},
        ];
    
    const NextArrow = ({ onClick }) => {
        return (
        <div className="arrow next" onClick={onClick}>
            <MdChevronRight size={40}/>
        </div>
        );
    };

    const PrevArrow = ({ onClick }) => {
        return (
        <div className="arrow prev" onClick={onClick}>
            <MdChevronLeft size={40}/>
        </div>
        );
    };

    const settings = {
      infinite: true,
      speed: 1000,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 2000,
      pauseOnHover: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
        <div className="main">
            <p>Sectors</p>
            <Slider {...settings}>
                {
                slides.map((slide, index) => {
                    return (
                        <div className="card">
                            <div className="card-image" style={{backgroundImage:`url(${slide.image})`,backgroundSize:'cover'}}></div>
                            <p className="card-title">{slide.title}</p>
                        </div>
                    )
                })
                }
            </Slider>
        </div>
    );
};

export default CardSlider2;