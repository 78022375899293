import React from "react";
import styled from "styled-components";
import "./HomeProducts.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const HomeProducts = () => {

    const products = [
        {image:"/images/mainslide/paperteacup.png", title:"210 ML Paper Tea Cup"},
        {image:"/images/mainslide/printedsandwichwrapper.png", title:"Printed Sandwich Wrapper"},
        {image:"/images/mainslide/paperteacup.png", title:"180 ML Paper Tea Cup"},
        {image:"/images/mainslide/mgwhitesandwichpaper.png", title:"Mg White Sandwich Paper"},
    ];
    const settings = {
      infinite: true,
      speed: 1000,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 2000,
      pauseOnHover: true,
      nextArrow: false,
      prevArrow: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
        <div className="HomeProductsMain">
            <p>Our Products</p>
            <Slider {...settings}>
                {
                products.map((product, index) => {
                    return (
                        <div className="productcard">
                            <div className="productcard-image" style={{backgroundImage:`url(${product.image})`,backgroundSize:'cover'}}></div>
                            <p className="productcard-title">{product.title}</p>
                        </div>
                    )
                })
                }
            </Slider>
            <a href="/Products">View All</a>
        </div>
    );
};


export default HomeProducts;