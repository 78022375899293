import React from "react";
import styled from "styled-components";
import Popup from 'reactjs-popup';
//import 'reactjs-popup/dist/index.css';

const FoodWrappingPaper = () => {

    const FoodWrappingPapers = [
        {image:"/images/mainslide/mgwhitesandwichpaper.png", name:"Mg White Sandwich Paper", size:"24 x 34 Cms", noofsheetsperpack:"500", quantity:"2000 Pcs", color:"White", material:"Paper Sheet", pattern:"Plane", usageorapplication:"Sandwich Wrap", papergsm:"Less than 80", brand:"Mafaz Exports", packagingdetails:"650 gms x 10 Pkt", boxweightkg:"7.2 KG", deliverytime:"30-45 Days"},
        {image:"/images/mainslide/printedsandwichwrapper.png", name:"Printed Sandwich Wrapper", size:"Updating", noofsheetsperpack:"Updating", quantity:"1000 Pcs", color:"Orange/Red", material:"Paper Sheet", pattern:"Printed", usageorapplication:"Sandwich Wrap", papergsm:"Updating", brand:"Mafaz Exports", packagingdetails:"1 Kg x 5 Pkt", boxweightkg:"Updating", deliverytime:"30-45 Days"},
    ];
    return (
        <FoodWrappingPaperMain>
        <h2>FOOD WRAPPING PAPER</h2>
            <FoodWrappingPaperCard>
                {
                    FoodWrappingPapers.map((FoodWrappingPaper, index) => {
                        return (
                            <div className="FoodWrappingPaperCardContaner">
                                <Popup trigger={
                                    <div className="FoodWrappingPaperCardMain">
                                        <div className="FoodWrappingPaperCard">
                                            <div className="FoodWrappingPaperCard-image" style={{backgroundImage:`url(${FoodWrappingPaper.image})`,backgroundSize:'cover'}}></div>
                                            <p className="FoodWrappingPaperCard-details">
                                            Name: {FoodWrappingPaper.name}<br />
                                            Size: {FoodWrappingPaper.size}<br />
                                            No. of Sheets Per Pack: {FoodWrappingPaper.noofsheetsperpack}<br />
                                            Quantity: {FoodWrappingPaper.quantity}<br />
                                            </p>
                                        </div>
                                        <div className="FoodWrappingPaperCard2">
                                            <p className="FoodWrappingPaperCard2-details">
                                                Name: {FoodWrappingPaper.name}<br />
                                                Size: {FoodWrappingPaper.size}<br />
                                                No. of Sheets Per Pack: {FoodWrappingPaper.noofsheetsperpack}<br />
                                                Quantity: {FoodWrappingPaper.quantity}<br />
                                                Color: {FoodWrappingPaper.color}<br />
                                                Material: {FoodWrappingPaper.material}<br />
                                                Pattern: {FoodWrappingPaper.pattern}<br />
                                                Usage/Application: {FoodWrappingPaper.usageorapplication}<br />
                                                Paper GSM: {FoodWrappingPaper.papergsm}<br />
                                                Brand: {FoodWrappingPaper.brand}<br />
                                                Packaging Details: {FoodWrappingPaper.packagingdetails}<br />
                                                Box Weight KG: {FoodWrappingPaper.boxweightkg}<br />
                                                Delivery Time: {FoodWrappingPaper.deliverytime}<br />
                                            </p>
                                        </div>
                                    </div>
                                    } modal >
                                    {close => (
                                        <FoodWrappingPaperModalMain>
                                            <button className="closePopup" onClick={close}>
                                            &times;
                                            </button>
                                            <div className="headerPopup"> {FoodWrappingPaper.name} </div>
                                            <div className="imagePopup"> <img src={FoodWrappingPaper.image} /> </div>
                                            <div className="contentmainPopup">
                                                Name: {FoodWrappingPaper.name}<br />
                                                Size: {FoodWrappingPaper.size}<br />
                                                No. of Sheets Per Pack: {FoodWrappingPaper.noofsheetsperpack}<br />
                                                Quantity: {FoodWrappingPaper.quantity}<br />
                                                Color: {FoodWrappingPaper.color}<br />
                                                Material: {FoodWrappingPaper.material}<br />
                                                Pattern: {FoodWrappingPaper.pattern}<br />
                                                Usage/Application: {FoodWrappingPaper.usageorapplication}<br />
                                                Paper GSM: {FoodWrappingPaper.papergsm}<br />
                                                Brand: {FoodWrappingPaper.brand}<br />
                                                Packaging Details: {FoodWrappingPaper.packagingdetails}<br />
                                                Box Weight KG: {FoodWrappingPaper.boxweightkg}<br />
                                                Delivery Time: {FoodWrappingPaper.deliverytime}<br />
                                            </div>
                                        </FoodWrappingPaperModalMain>
                                        )}
                                </Popup>

                            </div>
                        )
                    })
                }
            </FoodWrappingPaperCard>
        </FoodWrappingPaperMain>
    );
};

const FoodWrappingPaperMain = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex-shrink: 1;
    flex-grow: 1;
    margin-bottom: 20px;
    font-family: 'Helvetica';
`;

const FoodWrappingPaperCard = styled.div`
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-shrink: 1;
    flex-grow: 1;
    flex-wrap: wrap;

    .FoodWrappingPaperCardContaner{
        overflow: hidden;
    }

    .FoodWrappingPaperCardMain{
        height: 290px;
        width: 290px;
        position: relative;
        transform-style: preserve-3d;
        transition: all 1s linear;
        cursor: pointer;
        margin-bottom: 10px;

    }

    .FoodWrappingPaperCard,
    .FoodWrappingPaperCard2 {
        /*border: 3px solid #0C2037;*/
        width: 100%;
        height: 100%;
        background: #131826;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 2px 10px 3px #0000001f;
        /*box-shadow: 6px 0px 1px 1px #131826;*/
        position: absolute;
    }
    .FoodWrappingPaperCard-image {
        width: 100%;
        height: 65%;
        background-color: white;
        border-radius: 10px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        margin-top: 5px;
    }
    .FoodWrappingPaperCard-details {
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;
        color: white;
        margin: 3px;
        height: 35%;
    }

    .FoodWrappingPaperCard2-details {
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;
        color: white;
        margin: auto;
    }

    .FoodWrappingPaperCard {
        z-index: 5;
        position: absolute;
        backface-visibility: hidden;
    }


    .FoodWrappingPaperCard2::before{
    content: '';
    position: absolute;
    clip-path: circle(50% at 65% 24%);
    background-color: #EF5626;
    height: 150px;
    width: 150px;
    right: 0;
    border-radius: 10px;
    }

    .FoodWrappingPaperCardContaner:hover .FoodWrappingPaperCardMain{
        transform: rotateY(180deg);
    }
    .FoodWrappingPaperCard2{
        transform: rotateY(180deg);
    }
    .FoodWrappingPaperCardContaner:hover .FoodWrappingPaperCard2-details{
        transform: translate(-180deg);
        transition: 2s ease;
    }



    @media (max-width: 768px) {
        width: 90%;
        .FoodWrappingPaperCardMain{
        height: 210px;
        width: 210px;
        margin-bottom: 10px;

    }
        .FoodWrappingPaperCard,
        .FoodWrappingPaperCard2 {
            /*border: 3px solid #0C2037;*/
            width: 100%;
            height: 100%;
            }
        .FoodWrappingPaperCard-image {
            width: 100%;
            height: 65%;
        }
        .FoodWrappingPaperCard-details,
        .FoodWrappingPaperCard2-details {
            font-size: 12px;
        }
        .FoodWrappingPaperCard-details {
            height: 35%;
        }
        .FoodWrappingPaperCard2-details {
            font-size: 12px;
            height: 100%;
        }
        .FoodWrappingPaperCard2::before{
            content: '';
            position: absolute;
            clip-path: circle(50% at 65% 24%);
            background-color: #EF5626;
            height: 100px;
            width: 100px;
            right: 0;
            border-radius: 10px;
        }
    }
`;

const FoodWrappingPaperModalMain = styled.div`
    font-size: 1rem;
    font-family: 'Helvetica';
    button{
        background: transparent;
        border:none;
    }
    .headerPopup {
        border-bottom: 1px solid white;
        font-size: 1.2rem;
        text-align: center;
        padding: 15px 20px 10px 20px;
    }
    .imagePopup {
        display: flex;
        position: center;
        align-items:center;
        justify-content:center;
    }
    .imagePopup > img{
        height: 40%;
        width: 40%;
    }
    .contentmainPopup {
        width: 100%;
        height: 100%;
        padding: 0px 0px 10px 0px;
        display: flex;
        position: center;
        justify-content: center;
        text-align: center;
    }
    .closePopup {
        cursor: pointer;
        position: absolute;
        display: block;
        padding: 2px 5px;
        line-height: 20px;
        right: 3px;
        top: 3px;
        font-size: 1.6rem;
        background: #EF5626;
        border-radius: 50%;
        color:white;
    }
    @media (max-width:768px) {
        .imagePopup > img{
            height: 70%;
            width: 70%;
    }
    }
`;

export default FoodWrappingPaper;