export const sliderData = [
    {
        image: "/images/mainslide/paperteacup.png",
        heading: "210 ML Disposable Paper Tea Cup",
        desc: "",
    },
    {
        image: "/images/mainslide/mgwhitesandwichpaper.png",
        heading: "Mg White Sandwich Paper",
        desc: "",
    },
    {
        image: "/images/mainslide/paperteacup.png",
        heading: "180 ML Disposable Paper Tea Cup",
        desc: "",
    },
    {
        image: "/images/mainslide/printedsandwichwrapper.png",
        heading: "Printed Sandwich Wrapper",
        desc: "",
    },
];