import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Company from "./components/Company";
import Products from "./components/Products";
import Contactus from "./components/Contactus";
import ErrorPage from "./components/ErrorPage";
import PaperCup from "./components/Products/PaperCup";
import FoodWrappingPaper from "./components/Products/FoodWrappingPaper";
import HygieneAndProtection from "./components/Products/HygieneAndProtection";
import AluminiumContainer from "./components/Products/AluminiumContainer";


function App() {
  return (
    <div className="App">
      <Header />
      <div>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="company" element={<Company />} />
          <Route exact path="products" element={<Products />} >
            <Route exact path="papercup" element={<PaperCup />} />
            <Route exact path="foodwrappingpaper" element={<FoodWrappingPaper />} />
            <Route exact path="hygieneandprotection" element={<HygieneAndProtection />} />
            <Route exact path="aluminiumcontainer" element={<AluminiumContainer />} />
          </Route>
          <Route exact path="contactus" element={<Contactus />} />
          <Route path="*" element={<ErrorPage/>} />
        </Routes>
      </Router>
      </div>
      <Footer />
    </div>
  );
}

export default App;
