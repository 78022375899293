import React from "react";
import styled from "styled-components";


const Footer = () => {
    return (
        <Container>
            <Part1>
                <Links>
                    <h4>Quick Links</h4>
                    <a href="/">
                        <span>Home</span>
                    </a>
                    <a href="/company">
                        <span>Company</span>
                    </a>
                    <a href="/products/papercup"> 
                        <span>Products</span>
                    </a>
                    <a href="/contactus">
                        <span>Contact Us</span>
                    </a>
                </Links>
                <Categories>
                    <h4>Categories</h4>
                    Packaging Products<br />
                    Fruits<br />
                    Vegitables<br />
                    Cloths<br />
                    Spices<br />
                </Categories>
                <Address>
                        <h4>Our Office</h4>
                        MAFAZ EXPORTS PVT LTD<br />
                        Building No. 61A Iringatiri P O<br />
                        Karuvarakundu Malappuram KL 676523 IN<br />
                        <div>
                            <a href="tel:+919645706666">
                                <img src="/images/phone-icon.svg" alt="" />
                                <span>+919645706666</span>
                            </a>
                            <a href="mailto:sales@mafazexports.com">
                                <img src="/images/mail-icon.svg" alt="" />
                                <span>sales@mafazexports.com</span>
                            </a>
                        </div>
                </Address>
                <Followus>
                    <h4>Follow Us</h4>
                    <div>
                        <a href="https://wa.me/+919645706666">
                            <img src="/images/whatsapp-icon.svg" alt="" />
                        </a>
                        <a href="https://www.facebook.com/MafazExports">
                            <img src="/images/fb-icon.svg" alt="" />
                        </a>
                        <a href="https://www.instagram.com/mafazexports">
                            <img src="/images/insta-icon.svg" alt="" />
                        </a>
                        <a href="https://www.linkedin.com/in/mafazexports">
                            <img src="/images/linkedin-icon.svg" alt="" />
                        </a>
                        <a>
                            <img src="/images/twitter-icon.svg" alt="" />
                        </a>
                    </div>
                </Followus>
            </Part1>
            <End>
                Copyright 2022 <img src="/images/cpyrgt-icon.svg" alt="" /> Mafaz Exports Pvt Ltd - All Rights Reserved.
            </End>
        </Container>

    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #131826;
    color: white;
    border-radius: 10px 10px 0 0;
    //border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    //left: 0;
    padding: 10px 0px;
    position: relative;
    bottom: 0px;
    width: 100vw;
    //z-index: 100;
    //justify-content: bottom;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        max-width: 100vw;
        max-height: 100vh;
    }
`;

const Part1 = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-bottom: 8px;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        max-width: 100vw;
        max-height: 100vh;
        align-items: right;
    }
`;

const Links = styled.div`
    display: flex;
    flex-direction: column;
    background: transparent;
    padding-right: 20px;
    h4 {
        color: #F05627;
        margin-bottom: 7px;
    }
    a {
        text-decoration: none;

    }
    span {
        color: white;
        display: flex;
        align-items: center;
    }
`;

const Address = styled.div`
    h4 {
        color: #F05627;
        margin-bottom: 7px;
    }
    display: flex;
    flex-direction: column;
    color: white;
    a {
        text-decoration: none;
        color: white;
        display: flex;
    }
`;

const Categories = styled.div`
    display: flex;
    flex-direction: column;
    h4 {
        color: #F05627;
        margin-bottom: 7px;
    }
`;

const Followus = styled.div`
    display: flex;
    flex-direction: column;
    h4 {
        color: #F05627;
        margin-bottom: 7px;

    }
`;

const End = styled.div` 
    display: flex;
    flex-direction: row;
    color: #F3F3F5;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    border-top: 1px solid #F3F3F5;

`;

export default Footer;