import React from "react";
import styled from "styled-components";
import Popup from 'reactjs-popup';
//import 'reactjs-popup/dist/index.css';

const PaperCup = () => {

    const PaperCups = [
        {image:"/images/mainslide/paperteacup1.png", name:"Disposable Paper Tea Cup", volume:"6 oz", capacity:"180 ML", quantity:"1000 Pcs", color:"Black & Yellow", material:"Paper", pattern:"Printed", usageorapplication:"Parties", papergsm:"190", brand:"Mafaz Exports", packagingdetails:"50 Pcs x 20 Pkt", deliverytime:"30-45 Days"},
        {image:"/images/mainslide/paperteacup1.png", name:"Disposable Paper Tea Cup", volume:"7 oz", capacity:"210 ML", quantity:"1000 Pcs", color:"Black & Yellow", material:"Paper", pattern:"Printed", usageorapplication:"Parties", papergsm:"230", brand:"Mafaz Exports", packagingdetails:"50 Pcs x 20 Pkt", deliverytime:"30-45 Days"},
        {image:"/images/mainslide/paperteacup1.png", name:"Disposable Paper Tea Cup", volume:"8 oz", capacity:"250 ML", quantity:"1000 Pcs", color:"Black & Yellow", material:"Paper", pattern:"Printed", usageorapplication:"Parties", papergsm:"260", brand:"Mafaz Exports", packagingdetails:"50 Pcs x 20 Pkt", deliverytime:"30-45 Days"},
    ];
    return (
        <PaperCupMain>
        <h2>PAPER CUP</h2>
            <PaperCupCard>
                {
                    PaperCups.map((papercup, index) => {
                        return (
                            <div className="PaperCupCardContaner">
                                <Popup trigger={
                                    <div className="PaperCupCardMain">
                                        <div className="PaperCupCard">
                                            <div className="PaperCupCard-image" style={{backgroundImage:`url(${papercup.image})`,backgroundSize:'cover'}}></div>
                                            <p className="PaperCupCard-details">
                                            Name: {papercup.name}<br />
                                            Volume: {papercup.volume}<br />
                                            Capacity: {papercup.capacity}<br />
                                            Quantity: {papercup.quantity}<br />
                                            </p>
                                        </div>
                                        <div className="PaperCupCard2">
                                            <p className="PaperCupCard2-details">
                                                Name: {papercup.name}<br />
                                                Volume: {papercup.volume}<br />
                                                Capacity: {papercup.capacity}<br />
                                                Quantity: {papercup.quantity}<br />
                                                Color: {papercup.color}<br />
                                                Material: {papercup.material}<br />
                                                Pattern: {papercup.pattern}<br />
                                                Usage/Application: {papercup.usageorapplication}<br />
                                                Paper GSM: {papercup.papergsm}<br />
                                                Brand: {papercup.brand}<br />
                                                Packaging Details: {papercup.packagingdetails}<br />
                                                Delivery Time: {papercup.deliverytime}<br />
                                            </p>
                                        </div>
                                    </div>
                                    } modal >
                                    {close => (
                                        <ModalMain>
                                            <button className="closePopup" onClick={close}>
                                            &times;
                                            </button>
                                            <div className="headerPopup"> {papercup.capacity} {papercup.name} </div>
                                            <div className="imagePopup"> <img src={papercup.image} /> </div>
                                            <div className="contentmainPopup">
                                                Name: {papercup.name}<br />
                                                Volume: {papercup.volume}<br />
                                                Capacity: {papercup.capacity}<br />
                                                Quantity: {papercup.quantity}<br />
                                                Color: {papercup.color}<br />
                                                Material: {papercup.material}<br />
                                                Pattern: {papercup.pattern}<br />
                                                Usage/Application: {papercup.usageorapplication}<br />
                                                Paper GSM: {papercup.papergsm}<br />
                                                Brand: {papercup.brand}<br />
                                                Packaging Details: {papercup.packagingdetails}<br />
                                                Delivery Time: {papercup.deliverytime}<br />
                                            </div>
                                        </ModalMain>
                                        )}
                                </Popup>

                            </div>
                        )
                    })
                }
            </PaperCupCard>
        </PaperCupMain>
    );
};

const PaperCupMain = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex-shrink: 1;
    flex-grow: 1;
    margin-bottom: 20px;
    font-family: 'Helvetica';
`;

const PaperCupCard = styled.div`
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-shrink: 1;
    flex-grow: 1;
    flex-wrap: wrap;

    .PaperCupCardContaner{
        overflow: hidden;
    }

    .PaperCupCardMain{
        height: 290px;
        width: 290px;
        position: relative;
        transform-style: preserve-3d;
        transition: all 1s linear;
        cursor: pointer;
        margin-bottom: 10px;
    }

    .PaperCupCard,
    .PaperCupCard2 {
        /*border: 3px solid #0C2037;*/
        width: 100%;
        height: 100%;
        background: #131826;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 2px 10px 3px #0000001f;
        /*box-shadow: 6px 0px 1px 1px #131826;*/
        position: absolute;
    }
    .PaperCupCard-image {
        width: 100%;
        height: 65%;
        background-color: white;
        border-radius: 10px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        margin-top: 5px;
    }
    .PaperCupCard-details {
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;
        color: white;
        margin: 3px;
        height: 35%;
    }

    .PaperCupCard2-details {
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;
        color: white;
        margin: auto;
    }

    .PaperCupCard {
        z-index: 5;
        position: absolute;
        backface-visibility: hidden;
    }


    .PaperCupCard2::before{
    content: '';
    position: absolute;
    clip-path: circle(50% at 65% 24%);
    background-color: #EF5626;
    height: 150px;
    width: 150px;
    right: 0;
    border-radius: 10px;
    }

    .PaperCupCardContaner:hover .PaperCupCardMain{
        transform: rotateY(180deg);
    }
    .PaperCupCard2{
        transform: rotateY(180deg);
    }
    .PaperCupCardContaner:hover .PaperCupCard2-details{
        transform: translate(-180deg);
        transition: 2s ease;
    }



    @media (max-width: 768px) {
        width: 90%;
        .PaperCupCardMain{
        height: 210px;
        width: 210px;
        margin-bottom: 10px;

    }
        .PaperCupCard,
        .PaperCupCard2 {
            /*border: 3px solid #0C2037;*/
            width: 100%;
            height: 100%;
            }
        .PaperCupCard-image {
            width: 100%;
            height: 65%;
        }
        .PaperCupCard-details,
        .PaperCupCard2-details {
            font-size: 12px;
        }
        .PaperCupCard-details {
            height: 35%;
        }
        .PaperCupCard2-details {
            font-size: 12px;
            height: 100%;
        }
        .PaperCupCard2::before{
            content: '';
            position: absolute;
            clip-path: circle(50% at 65% 24%);
            background-color: #EF5626;
            height: 100px;
            width: 100px;
            right: 0;
            border-radius: 10px;
        }
    }
`;

const ModalMain = styled.div`
    font-size: 1rem;
    font-family: 'Helvetica';
    button{
        background: transparent;
        border:none;
    }
    .headerPopup {
        border-bottom: 1px solid white;
        font-size: 1.2rem;
        text-align: center;
        padding: 15px 20px 10px 20px;
    }
    .imagePopup {
        display: flex;
        position: center;
        align-items:center;
        justify-content:center;
    }
    .imagePopup > img{
        height: 40%;
        width: 40%;
    }
    .contentmainPopup {
        width: 100%;
        height: 100%;
        padding: 0px 0px 10px 0px;
        display: flex;
        position: center;
        justify-content: center;
        text-align: center;
    }
    .closePopup {
        cursor: pointer;
        position: absolute;
        display: block;
        padding: 2px 5px;
        line-height: 20px;
        right: 3px;
        top: 3px;
        font-size: 1.6rem;
        background: #EF5626;
        border-radius: 50%;
        color:white;
    }
    @media (max-width:768px) {
        .imagePopup > img{
            height: 70%;
            width: 70%;
    }
    }
`;

export default PaperCup;