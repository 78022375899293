import React from "react";
import styled from "styled-components";
import SliderCompany from "./slider/SliderCompany";


const Company = () => {
    return (
        <CompanyMain>
            <SliderCompany />
            <AboutUs>
                <First>
                    <h1>About Us</h1>
                    <img src="/images/mafazexports.svg" />
                </First>
                <Second>
                    <span>Mafaz Exports Private Limited is a reputed exporting company in India 
                            which deals with exporting business of food packaging products, fruits, 
                            vegitables, spices, cloths, etc. We promote a sustainable and profitable 
                            business through out the world. We provide best deals to all our 
                            customers throughout the globe without compromising the quality of 
                            products we deliver.</span>
                </Second>
            </AboutUs>
            <Mission>
                <MissionFirst>
                    <h1>Mission</h1>
                    <img src="/images/mafazexports.svg" />
                </MissionFirst>
                <MissionSecond>
                    <span>We are well determined to develop valuable, secure and innovative 
                    packaging solutions with high-quality measures for the global retail 
                    and foodservice enterprises.</span>
                </MissionSecond>
            </Mission>
        </CompanyMain>
    );
};

const CompanyMain = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    a {
        text-decoration: none;
        color: #131826;
    }
    @media (max-width: 768px) {
        img {
            max-width: 50vw;
            max-height: 50vh;
        }
    }
`;

const CommonCard = styled.div`
    text-align: center;
    overflow: hidden;
    margin-bottom: 20px;
    margin-top: 30px;
    background-color: #fff;
    border-radius: 3px;
    position: relative;
    border: none;
    box-shadow: 0 0 0 1px rgba(0 0 0 / 15%), 0 0 0 rgba(0 0 0 / 20%);
`;

const AboutUs = styled(CommonCard)`
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 85%;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
`;

const Mission = styled(AboutUs)``;

const First = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 30%;
    align-items: center;
    background: linear-gradient(180deg, #1C263D, white);
    color: #fff;
    position: relative;
    padding-left: 50px;
    padding-right: 50px;
    justify-content: center;
    align-items: center;
    h1 {
        padding-top: 30px;
        padding-bottom: 0px;
        font-weight: 600;
        font-size: 36px;
        position: center;
    }

    img {
        width: 100%;
        height: 100%;
        position: center;
        opacity: 1;
        }
    @media (max-width: 768px) {
        max-width: 100%;
        max-height: 100%;
        img {
            width: 30%;
            height: 30%;
            position: relative;
        }
        h1 {
            padding-top: 10px;
            padding-bottom: 0px;
            font-weight: 600;
            font-size: 36px;
        }
    }
`;

const Second = styled.div`
    max-width: 70%;
    margin: auto;
    top: 50%;
    display: flex;
    flex-direction: column;
    opacity: 1;
    flex-grow: 1;
    position: relative;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: 'Helvetica';
    font-size: 22px;
    color: #131826;
    @media (max-width: 768px) {
        margin: auto;
        max-width: 95%;
        padding-top: 50px;
        padding-bottom: 50px;
        font-size: 20px;

    }
`;

const MissionFirst = styled(First)``;

const MissionSecond = styled(Second)``;


export default Company;