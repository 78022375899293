import React from "react";
import styled from "styled-components";
import { Link, Outlet } from "react-router-dom";



const Products = () => {
    return (
        <ProductMain>
        <Nav>
            <Link exact to="/products/papercup" className="design">Paper Cup</Link>
            <Link to="/products/foodwrappingpaper" className="design">Food Wrapping Paper</Link>
            <Link to="/products/hygieneandprotection" className="design">Hygiene And Protection</Link>
            <Link to="/products/aluminiumcontainer" className="design">Aluminium Container</Link>
        </Nav>
        <OutPut>
            <Outlet />
        </OutPut>
        </ProductMain>
    );
};

const ProductMain = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    a {
        text-decoration: none;
        color: #131826;
    }
    @media (max-width: 768px) {
        img {
            max-width: 50vw;
            max-height: 50vh;
        }
    }
`;

const Nav = styled.div`
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-wrap: nowrap;
    justify-content: space-around;
    position: relative;
    align-items: center;
    padding: 15px 10px 10px 10px;
    margin: auto;
    .design {
        height: 6vh;
        color: white;
        background: #131826;
        border-radius: 15px;
        padding: 10px 15px 10px 15px;
        font-family: 'Helvetica';
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: center;
        text-align: center;
        font-weight: 600;

    }
    .design:hover {
        font-size: 18px;
        background: linear-gradient(180deg, #1C263D, #1C3251);
    }
    @media (max-width: 768px) {
        .design {
            margin-right: 2px;
            margin-left: 2px;
            font-size: 12px;
            font-weight: 550;
        }
        .design:hover {
            font-size: 14px;
        }
    }
`;
const OutPut = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-around;
    margin: auto;
    padding: 10px;
`;

export default Products;