import React, { useState, useRef } from "react";
import styled from "styled-components";
import emailjs from '@emailjs/browser';
//import axios from "axios";

const Contactus = () => {

    //const [name, setName]=useState('');
    //const [email, setEmail]=useState('');
    //const [address, setAddress]=useState('');
    //const [message, setMessage]=useState('');

    /*const handleSubmit=(e)=>{
        //e.preventDefault();
        //console.log(name, email, address, message);
        const data={
            name:name,
            email:email,
            address:address,
            message:message
        }
        axios.post("https://script.google.com/macros/s/AKfycbwX-39VuJvAvpt8sALoy0fTDs9QdPB6oa03RI04/exec",data)
    };*/

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_fb1fysp', 'template_f74m6p7', form.current, 'I-v8JBxZ0tPWZAVld')
        .then((result) => {
            console.log(result.text);
            console.log("Submitted");
            e.target.reset();
        }, (error) => {
            console.log(error.text);
        });
    };
    
    return (
        <ContactusMain>
            <ContactusPart1>
                <ContactusAddress>
                        <h3>Our Office</h3>
                        MAFAZ EXPORTS PVT LTD<br />
                        Building No. 61A Iringatiri P O<br />
                        Karuvarakundu Malappuram KL 676523 IN<br />
                        <div>
                            <a href="tel:+919645706666">
                                <img src="/images/phone-icon2.svg" alt="" />
                                <span>+919645706666</span>
                            </a>
                            <a href="mailto:sales@mafazexports.com">
                                <img src="/images/mail-icon2.svg" alt="" />
                                <span>sales@mafazexports.com</span>
                            </a>
                        </div>
                </ContactusAddress>
                <ContactusFollowus>
                    <h3>Follow Us</h3>
                    <div>
                        <a href="https://wa.me/+919645706666">
                            <img src="/images/whatsapp-icon2.svg" alt="" />
                        </a>
                        <a href="https://www.facebook.com/MafazExports">
                            <img src="/images/fb-icon2.svg" alt="" />
                        </a>
                        <a href="https://www.instagram.com/mafazexports">
                            <img src="/images/insta-icon2.svg" alt="" />
                        </a>
                        <a href="https://www.linkedin.com/in/mafazexports">
                            <img src="/images/linkedin-icon2.svg" alt="" />
                        </a>
                        <a>
                            <img src="/images/twitter-icon2.svg" alt="" />
                        </a>
                    </div>
                </ContactusFollowus>
            </ContactusPart1>
            <ContactusEnquiry>
                <h3>For Enquiry</h3>
                <form ref={form} onSubmit={sendEmail} /*action="https://script.google.com/macros/s/AKfycbwX-39VuJvAvpt8sALoy0fTDs9QdPB6oa03RI04/exec" method="post"*/>
                    <div className="inputBox"><input type="text" required="required" name="user_name" /*onChange={(e)=>setName(e.target.value)} value={name}*/ /><span>Name</span></div>
                    <div className="inputBox"><input type="text" required="required" name="user_company"/*onChange={(e)=>setEmail(e.target.value)} value={email}*/ /><span>Company</span></div>
                    <div className="inputBox"><input type="text" required="required" name="user_designation"/*onChange={(e)=>setEmail(e.target.value)} value={email}*/ /><span>Designation</span></div>
                    <div className="inputBox"><input type="text" required="required" name="user_phone"/*onChange={(e)=>setEmail(e.target.value)} value={email}*/ /><span>Phone</span></div>
                    <div className="inputBox"><input type="text" required="required" name="user_email"/*onChange={(e)=>setEmail(e.target.value)} value={email}*/ /><span>Email</span></div>
                    <div className="inputBox"><input type="text" required="required" name="user_address"/*onChange={(e)=>setAddress(e.target.value)} value={address}*/ /><span>Address</span></div>
                    <div className="inputBox"><input type="text" required="required" name="user_country"/*onChange={(e)=>setEmail(e.target.value)} value={email}*/ /><span>Country</span></div>
                    <div className="inputBox"><input type="text" required="required" name="productenquiry"/*onChange={(e)=>setMessage(e.target.value)} value={message}*/ /><span>Product Enquiry</span></div>
                    <div className="inputBox"><input type="text" required="required" name="productquantity"/*onChange={(e)=>setMessage(e.target.value)} value={message}*/ /><span>Product Quantity</span></div>
                    <button className="submitbutton">SUBMIT</button>
                </form>
            </ContactusEnquiry>
        </ContactusMain>
    );
        
};

const ContactusMain = styled.div`
    font-family: "Helvetica";
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    font-weight: 550;
    font-size: 1rem;
    line-height: 23px;
    a {
        text-decoration: none;
        color: #131826;
    }
    @media (max-width: 768px) {
        img {
            max-width: 250vw;
            max-height: 250vh;
        }
    }
`;

const ContactusPart1 = styled.div`
    margin-right: auto;
    padding-left: 2rem;
    @media (max-width: 768px) {
        padding-left: 1rem;
    }
`;

const ContactusAddress = styled.div`
    h3 {
        color: #F05627;
        margin-bottom: 7px;
    }
    display: flex;
    flex-direction: column;
    color: #212A36;
    a {
        text-decoration: none;
        color: #212A36;
        display: flex;
    }
    img {
        width: 25px;
        height: 25px;
    }
    img:hover{
        width: 26px;
        height: 26px;
    }
`;

const ContactusFollowus = styled.div`
    display: flex;
    flex-direction: column;
    h3 {
        color: #F05627;
        margin-bottom: 7px;
    }
    div {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
        width: 45px;
        height: 45px;
    }
    img {
        width: 35px;
        height: 35px;
    }
    img:hover{
        width: 45px;
        height: 45px;
    }
`;

const ContactusEnquiry = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: center;
    margin: auto;
    padding-right: 1.5rem;
    h3 {
        margin: 20px 0px 20px 0px;
    }

    .inputBox {
        display: flex;
        margin: auto;
        flex-direction: column;
        position: relative;
        width: 50vw;
        padding-bottom: 25px;
    }
    .inputBox input {
        width: 100%;
        padding: 13px;
        border: 2px solid #131926;
        background: white;
        border-radius: 5px;
        outline: none;
        color: #131926;
        font-size: 1em;
        transition: 0.5s;
        margin: auto;
    }
    .inputBox span {
        position: absolute;
        left: 0;
        padding: 10px;
        pointer-events: none;
        font-size: 1em;
        color: #131926;
        opacity: 50%;
        transition: 0.5s;
    }
    .inputBox input:valid ~ span,
    .inputBox input:focus ~ span 
    {
        color: #F05828;
        opacity: 1;
        transform: translateX(10px) translateY(-10px);
        font-size: 0.72em;
        padding: 0px 10px;
        background: white;
        border-left: 2px solid #F05828;
        border-right: 2px solid #F05828;
        height: 20%;
        letter-spacing: 0.2em;
        text-align: center;     
        padding-bottom: 5px;  
        text-transform: uppercase;
    }
    .inputBox input:valid,
    .inputBox input:focus
    {
        border: 2px solid #F05828;
    }
    .submitbutton {
        height: 50px;
        width: 120px;
        position: relative;
        align-items: center;
        display: flex;
        justify-content: center;
        border: none;
        color: white;
        background: #131926;
        border-radius: 30px;
        margin: 30px auto 20px auto;
        font-size: 1em;
        padding: auto;
        box-shadow: 0 4px 15px rgba(0,0,0,0.2);
        letter-spacing: 1px;
        font-size: 18px;
        font-weight: 500;
        text-decoration: none;
        overflow: hidden;
    }
    .submitbutton:hover {
        font-size: 19px;
        background: linear-gradient(180deg, #1C263D, #1C3251);
        cursor: pointer;
    }

    @media (max-width: 768px) {
        .inputBox {
            width: 70vw;
        }
    }
`;

export default Contactus;
