import React, { useState, useEffect } from "react";
import styled from "styled-components";
//import {AiOutlineArrowLeft} from "react-icons/ai";
//import {AiOutlineArrowRight} from "react-icons/ai";
import { MdChevronLeft,MdChevronRight } from "react-icons/md";
import {sliderData} from"./slider-data";
import "./Slider.css";


const Slider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const slideLength = sliderData.length;
    // slideLength = 1 2 3
    // currentSlide = 0 1 2

    const autoScroll = true;
    let slideInterval;
    let intervalTime = 5000;

    const nextSlide = () => {
        setCurrentSlide(currentSlide === slideLength - 1 ? 0 : currentSlide + 1);
    };

    const prevSlide = () => {
        setCurrentSlide(currentSlide === 0 ? slideLength - 1 : currentSlide - 1);
    };

    function auto() {
        slideInterval = setInterval(nextSlide, intervalTime);
    }

    useEffect(() => {
        setCurrentSlide(0);
    }, []);

    useEffect(() => {
        if (autoScroll) {
            auto();
        }
        return () => clearInterval(slideInterval);
    }, [currentSlide]);


    return (
        <div className="slider">
            <MdChevronLeft size={40} className="arrowmain prev"onClick={prevSlide}/>
            <MdChevronRight size={40} className="arrowmain next" onClick={nextSlide}/>

            {sliderData.map((slide, index) => {
                return (
                    <div className={index === currentSlide ? 
                    "slide current" : "slide"} key={index}>
                    {index === currentSlide && (
                        <>
                        <img src={slide.image} alt="slide" />
                        <div className= "content">
                            <p>{slide.heading}</p>
                            <p>{slide.desc}</p>
                            <hr />
                        </div>
                        </>
                    )}

                    </div>
                )
            })}

        </div>
    );
};

export default Slider;
