import React from "react";
import styled from "styled-components";
import Slider from "./slider/Slider";
import CardSlider2 from "./CardSlider2/CardSlider2";
import HomeProducts from "./HomeProducts/HomeProducts"

const Home = () => {

    return (
        <HomeMain>
        <Slider />
        <AboutMini>
            <span>Mafaz Exports Private Limited is a reputed exporting company in India
                which deals with exporting business of food packaging products, fruits, 
                vegitables, spices, cloths, etc... <a href="/company"> Read More</a></span>
        </AboutMini>
        <HomeProducts />
        <CardSlider2 />
        </HomeMain>
    );
};

const HomeMain = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    a {
        text-decoration: none;
        color: #131826;
    }
    @media (max-width: 768px) {
        img {
            max-width: 100vw;
            max-height: 100vh;
        }
    }
`;

const AboutMini = styled.div`
    padding: 40px 30px 30px 30px;
    font-weight: 500;
    font-size: 22px;
    justify-content: center;
    align-items: center;
    position: center;
    color: #0C2037;
    text-align: center;
    opacity: 1;
    a {
        font-weight: 500;
        font-size: 22px;
        color: #0C2037;
        opacity: .7;
    }

    a:hover {
        opacity: 1;
        color: #131826;
    }
     @media (max-width: 768px) {
            font-size: 18px;
            a {
                font-size: 18px;
            }
            a:hover {
                color: #131826;
            }
     }
`;


export default Home;