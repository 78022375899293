import React from "react";
import styled from "styled-components";



const Header = () => {
    return (
        <Container>
            <Contact>
                <div>
                </div>
                <div>
                    <a href="https://wa.me/+919645706666">
                        <img src="/images/whatsapp-icon.svg" alt="" />
                    </a>
                    <a href="https://www.facebook.com/MafazExports">
                        <img src="/images/fb-icon.svg" alt="" />
                    </a>
                    <a href="https://www.instagram.com/mafazexports">
                        <img src="/images/insta-icon.svg" alt="" />
                    </a>
                    <a href="https://www.linkedin.com/in/mafazexports">
                        <img src="/images/linkedin-icon.svg" alt="" />
                    </a>
                    <a>
                        <img src="/images/twitter-icon.svg" alt="" />
                    </a>
                    <a href="tel:+919645706666">
                        <img src="/images/phone-icon.svg" alt="" />
                    </a>
                    <a href="mailto:sales@mafazexports.com">
                        <img src="/images/mail-icon.svg" alt="" />
                    </a>
                </div>
            </Contact>
            <Content>
                <Logo>
                    <a href = "/">
                        <img src="/images/mafazexportslogo.svg" alt="" />
                    </a>
                </Logo>
                <Nav>
                    <NavListWrap>
                        <NavList /*className="active"*/ to="/">
                            <a href="/">
                                <span>Home</span>
                            </a>
                        </NavList>
                        <NavList>
                            <a href="/company">
                                <span>Company</span>
                            </a>
                        </NavList>
                        <NavList>
                            <a href="/products/papercup"> 
                                <span>Products</span>
                            </a>
                        </NavList>
                        <NavList>
                            <a href="/contactus">
                                <span>Contact Us</span>
                            </a>
                        </NavList>
                    </NavListWrap>
                </Nav>
            </Content>
        </Container>

    );
};


const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    left: 0;
    padding: 0;
    position: relative;
    top: 0;
    width: 100vw;
    z-index: 100;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        max-width: 100vw;
        max-height: 100vh;
    }
`;


const Contact = styled(Container)`
    background-color: #131826;
    display: flex;
    flex-direction: row;
    position: relative;
    border-radius: 0 0 10px 10px;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: row;
        max-width: 100vw;
        max-height: 100vh;
        position: relative;
        div {
            &:first-child{
                display: flex;
                flex-direction: column;
            }
        }
    }
    a {
        display: flex;
        flex-wrap: wrap;
        padding-right: 5px;
        height: 32px;
        span {
            color: white;
        }
    }
    div {
        &:first-child {
            a {
                text-decoration: none;

            }
            display: flex;
            padding: 0 15px;
            justify-content: space-around;
            margin-right: auto;
        }
        &:nth-child(2) {
            display: flex;
            flex-wrap: wrap;
            padding: 0 10px;
            margin-left: auto;
        }
    }
    img:hover{
        width: 30px;
        height: 30px;
    }
    
`;

const Content = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    //align-items: center;
    //margin: 0 auto;
    //min-height: 100%;
    //max-width: 1128px;
`;

const Logo = styled.span`
    display: flex;
    margin-right: auto;
    margin-left: 2rem;
    font-size: 0px;
    padding-top: 5px;
    a {
        max-width: 17rem;
        max-height: 17rem;
        img {
            width: 100%;
            height: 100%;
        }
    }
    @media (max-width: 768px) {
        margin-left: .6rem;
        a {
            max-width: 12rem;
            max-height: 12rem;
        }
    }
    
`;

const Nav = styled.nav`
    display: flex;
    margin-left: auto;
    margin-right: 30px;
    display: block;
    @media (max-width: 768px) {
        position: fixed;
        left: 0;
        bottom: 0;
        background: white;
        width: 100%;
    }
`;

const NavListWrap = styled.ul`
    display: flex;
    flex-wrap: nowrap;
    list-style-type: none;
    @media (max-width: 768px) {
        justify-content: center;
    }

    .active {
        span:after {
            content: "";
            transform: scaleX(1);
            border-bottom: 2px solid var(--white, #fff);
            bottom: 0;
            left: 0;
            position: absolute;
            transition: transform 0.2s ease-in-out;
            width: 100%;
            border-color: rgba(0, 0, 0, 0.9);

        }
    }

`;

const NavList = styled.li`
    display: flex;
    align-Items: center;
    a {
        align-items: center;
        background: transparent;
        display: flex;
        flex-direction: column;
        font-size: 12px;
        font-weight: 400;
        justify-content: center;
        line-height: 1.5;
        min-height: 42px;
        min-width: 80px;
        position: relative;
        text-decoration: none;

        span {
            color: rgba(0, 0, 0, 0.6);
            display: flex;
            align-items: center;
        }

        @media (max-width: 768px) {
            min-width: 70px;
        }
    }

    &:hover, 
    &:active {
        a {
            span {
                color: rgba(0, 0, 0, 0.9);
            }
        }
    }
`;




export default Header;