import React from "react";
import styled from "styled-components";
import Popup from 'reactjs-popup';
//import 'reactjs-popup/dist/index.css';

const AluminiumContainer = () => {

    const AluminiumContainers = [
        {image:"/images/mainslide/aluminiumcontainer.png", name:"83185 Aluminium Container", code:"83185", volume:"1800 ML", quantity:"Updating", size:"L= , W= , H= ", material:"Aluminium", cartonsize:"528 x 390 x 273", usageorapplication:"Food Packing", cbm:"0.05621616", rimtype:"Inverted Vertical Curl", brand:"Mafaz Exports", packagingdetails:"400 Pcs/Box", deliverytime:"30-45 Days"},
        {image:"/images/mainslide/aluminiumcontainer.png", name:"83190 Aluminium Container", code:"83190", volume:"2100 ML", quantity:"Updating", size:"L= , W= , H= ", material:"Aluminium", cartonsize:"435 x 370 x 320", usageorapplication:"Food Packing", cbm:"0.051504", rimtype:"Inverted Vertical Curl", brand:"Mafaz Exports", packagingdetails:"200 Pcs/Box", deliverytime:"30-45 Days"},
        {image:"/images/mainslide/aluminiumcontainer.png", name:"73365 Aluminium Container", code:"73365", volume:"3285 ML", quantity:"Updating", size:"L= , W= , H= ", material:"Aluminium", cartonsize:"332 x 257 x 266", usageorapplication:"Food Packing", cbm:"0.02269618", rimtype:"Inverted Vertical Curl", brand:"Mafaz Exports", packagingdetails:"100 Pcs/Box", deliverytime:"30-45 Days"},
        {image:"/images/mainslide/aluminiumcontainer.png", name:"831120 Aluminium Container", code:"831120", volume:"1000 ML", quantity:"Updating", size:"L= , W= , H= ", material:"Aluminium", cartonsize:"352 x 232 x 482", usageorapplication:"Food Packing", cbm:"0.03936204", rimtype:"Inverted Vertical Curl", brand:"Mafaz Exports", packagingdetails:"400 Pcs/Box", deliverytime:"30-45 Days"},
        {image:"/images/mainslide/aluminiumcontainer.png", name:"83120 Aluminium Container", code:"83120", volume:"1105 ML", quantity:"Updating", size:"L= , W= , H= ", material:"Aluminium", cartonsize:"475 x 360 x 240", usageorapplication:"Food Packing", cbm:"0.04104", rimtype:"Inverted Vertical Curl", brand:"Mafaz Exports", packagingdetails:"400 Pcs/Box", deliverytime:"30-45 Days"},
        {image:"/images/mainslide/aluminiumcontainer.png", name:"Deep Aluminium Cantainer", code:"9'' x 9'' x 2''", volume:"2100 ML", quantity:"Updating", size:"L= , W= , H= ", material:"Aluminium", cartonsize:"500 x 250 x 310", usageorapplication:"Food Packing", cbm:"0.0387500", rimtype:"Inverted Vertical Curl", brand:"Mafaz Exports", packagingdetails:"200 Pcs/Box", deliverytime:"30-45 Days"},
        {image:"/images/mainslide/aluminiumcontainer.png", name:"Shallow Aluminium Conatiner", code:"9'' x 9'' x 1.5''", volume:"1500 ML", quantity:"Updating", size:"L= , W= , H= ", material:"Aluminium", cartonsize:"500 x 250 x 300", usageorapplication:"Food Packing", cbm:"0.0375000", rimtype:"Inverted Vertical Curl", brand:"Mafaz Exports", packagingdetails:"200 Pcs/Box", deliverytime:"30-45 Days"},
        {image:"/images/mainslide/aluminiumcontainer.png", name:"6A Aluminium Container", code:"6A", volume:"670 ML", quantity:"Updating", size:"L= , W= , H= ", material:"Aluminium", cartonsize:"450 x 320 x 210", usageorapplication:"Food Packing", cbm:"0.03024", rimtype:"Inverted Vertical Curl", brand:"Mafaz Exports", packagingdetails:"500 Pcs/Box", deliverytime:"30-45 Days"},

    ];
    return (
        <AluminiumContainerMain>
        <h2>ALUMINIUM CONTAINER</h2>
            <AluminiumContainerCard>
                {
                    AluminiumContainers.map((AluminiumContainer, index) => {
                        return (
                            <div className="AluminiumContainerCardContaner">
                                <Popup trigger={
                                    <div className="AluminiumContainerCardMain">
                                        <div className="AluminiumContainerCard">
                                            <div className="AluminiumContainerCard-image" style={{backgroundImage:`url(${AluminiumContainer.image})`,backgroundSize:'cover'}}></div>
                                            <p className="AluminiumContainerCard-details">
                                            Name: {AluminiumContainer.name}<br />
                                            Code: {AluminiumContainer.code}<br />
                                            Volume: {AluminiumContainer.volume}<br />
                                            Quantity: {AluminiumContainer.quantity}<br />
                                            </p>
                                        </div>
                                        <div className="AluminiumContainerCard2">
                                            <p className="AluminiumContainerCard2-details">
                                                Name: {AluminiumContainer.name}<br />
                                                Code: {AluminiumContainer.code}<br />
                                                Volume: {AluminiumContainer.volume}<br />
                                                Quantity: {AluminiumContainer.quantity}<br />
                                                Size: {AluminiumContainer.size}<br />
                                                Carton Size: {AluminiumContainer.cartonsize}<br />
                                                Material: {AluminiumContainer.material}<br />
                                                Cbm: {AluminiumContainer.cbm}<br />
                                                Rim Type: {AluminiumContainer.rimtype}<br />
                                                Usage/Application: {AluminiumContainer.usageorapplication}<br />
                                                Brand: {AluminiumContainer.brand}<br />
                                                Packaging Details: {AluminiumContainer.packagingdetails}<br />
                                                Delivery Time: {AluminiumContainer.deliverytime}<br />
                                            </p>
                                        </div>
                                    </div>
                                    } modal >
                                    {close => (
                                        <AluminiumContainerModalMain>
                                            <button className="closePopup" onClick={close}>
                                            &times;
                                            </button>
                                            <div className="headerPopup"> {AluminiumContainer.name} </div>
                                            <div className="imagePopup"> <img src={AluminiumContainer.image} /> </div>
                                            <div className="contentmainPopup">
                                                Name: {AluminiumContainer.name}<br />
                                                Code: {AluminiumContainer.code}<br />
                                                Volume: {AluminiumContainer.volume}<br />
                                                Quantity: {AluminiumContainer.quantity}<br />
                                                Size: {AluminiumContainer.size}<br />
                                                Carton Size: {AluminiumContainer.cartonsize}<br />
                                                Material: {AluminiumContainer.material}<br />
                                                Cbm: {AluminiumContainer.cbm}<br />
                                                Rim Type: {AluminiumContainer.rimtype}<br />
                                                Usage/Application: {AluminiumContainer.usageorapplication}<br />
                                                Brand: {AluminiumContainer.brand}<br />
                                                Packaging Details: {AluminiumContainer.packagingdetails}<br />
                                                Delivery Time: {AluminiumContainer.deliverytime}<br />
                                            </div>
                                        </AluminiumContainerModalMain>
                                        )}
                                </Popup>

                            </div>
                        )
                    })
                }
            </AluminiumContainerCard>
        </AluminiumContainerMain>
    );
};

const AluminiumContainerMain = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex-shrink: 1;
    flex-grow: 1;
    margin-bottom: 20px;
    font-family: 'Helvetica';
`;

const AluminiumContainerCard = styled.div`
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-shrink: 1;
    flex-grow: 1;
    flex-wrap: wrap;

    .AluminiumContainerCardContaner{
        overflow: hidden;
    }

    .AluminiumContainerCardMain{
        height: 290px;
        width: 290px;
        position: relative;
        transform-style: preserve-3d;
        transition: all 1s linear;
        cursor: pointer;
        margin-bottom: 10px;

    }

    .AluminiumContainerCard,
    .AluminiumContainerCard2 {
        /*border: 3px solid #0C2037;*/
        width: 100%;
        height: 100%;
        background: #131826;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 2px 10px 3px #0000001f;
        /*box-shadow: 6px 0px 1px 1px #131826;*/
        position: absolute;
    }
    .AluminiumContainerCard-image {
        width: 100%;
        height: 65%;
        background-color: white;
        border-radius: 10px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        margin-top: 5px;
    }
    .AluminiumContainerCard-details {
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;
        color: white;
        margin: 3px;
        height: 35%;
    }

    .AluminiumContainerCard2-details {
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;
        color: white;
        margin: auto;
    }

    .AluminiumContainerCard {
        z-index: 5;
        position: absolute;
        backface-visibility: hidden;
    }


    .AluminiumContainerCard2::before{
    content: '';
    position: absolute;
    clip-path: circle(50% at 65% 24%);
    background-color: #EF5626;
    height: 150px;
    width: 150px;
    right: 0;
    border-radius: 10px;
    }

    .AluminiumContainerCardContaner:hover .AluminiumContainerCardMain{
        transform: rotateY(180deg);
    }
    .AluminiumContainerCard2{
        transform: rotateY(180deg);
    }
    .AluminiumContainerCardContaner:hover .AluminiumContainerCard2-details{
        transform: translate(-180deg);
        transition: 2s ease;
    }



    @media (max-width: 768px) {
        width: 90%;
        .AluminiumContainerCardMain{
        height: 210px;
        width: 210px;
        margin-bottom: 10px;

    }
        .AluminiumContainerCard,
        .AluminiumContainerCard2 {
            /*border: 3px solid #0C2037;*/
            width: 100%;
            height: 100%;
            }
        .AluminiumContainerCard-image {
            width: 100%;
            height: 65%;
        }
        .AluminiumContainerCard-details,
        .AluminiumContainerCard2-details {
            font-size: 12px;
        }
        .AluminiumContainerCard-details {
            height: 35%;
        }
        .AluminiumContainerCard2-details {
            font-size: 12px;
            height: 100%;
        }
        .AluminiumContainerCard2::before{
            content: '';
            position: absolute;
            clip-path: circle(50% at 65% 24%);
            background-color: #EF5626;
            height: 100px;
            width: 100px;
            right: 0;
            border-radius: 10px;
        }
    }
`;

const AluminiumContainerModalMain = styled.div`
    font-size: 1rem;
    font-family: 'Helvetica';
    button{
        background: transparent;
        border:none;
    }
    .headerPopup {
        border-bottom: 1px solid white;
        font-size: 1.2rem;
        text-align: center;
        padding: 15px 20px 10px 20px;
    }
    .imagePopup {
        display: flex;
        position: center;
        align-items:center;
        justify-content:center;
    }
    .imagePopup > img{
        height: 40%;
        width: 40%;
    }
    .contentmainPopup {
        width: 100%;
        height: 100%;
        padding: 0px 0px 10px 0px;
        display: flex;
        position: center;
        justify-content: center;
        text-align: center;
    }
    .closePopup {
        cursor: pointer;
        position: absolute;
        display: block;
        padding: 2px 5px;
        line-height: 20px;
        right: 3px;
        top: 3px;
        font-size: 1.6rem;
        background: #EF5626;
        border-radius: 50%;
        color:white;
    }
    @media (max-width:768px) {
        .imagePopup > img{
            height: 70%;
            width: 70%;
    }
    }
`;

export default AluminiumContainer;